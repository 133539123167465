<template>
  <div class="home">
    <div class="home__intro">
      <div class="home__content">
        <h1 class="home__title">placepengu.in</h1>
        <p>inserting a placeholder penguin has never been easier</p>
        <div class="home__maker">
          <input
            :class="{'invalid': $v.makerWidth.$invalid}"
            type="number"
            placeholder="width"
            min="0"
            max="200"
            v-model.lazy.number="makerWidth"
          >
          <input
            :class="{'invalid': $v.makerHeight.$invalid}"
            type="number"
            placeholder="height"
            min="0"
            max="200"
            v-model.lazy.number="makerHeight"
          >
          <select name="fit" v-model.lazy="makerFit">
            <option value="cover" selected>cover</option>
            <option value="contain">contain</option>
            <option value="fill">fill</option>
          </select>
          <select name="format" v-model.lazy="makerFormat">
            <option value="jpg" selected>jpg</option>
            <option value="png">png</option>
            <option value="webp">webp</option>
          </select>
        </div>
        <small class="home__limits">* dimensions are limited to 200px only in this tool</small>
        <pre>{{ makerURL }}</pre>
        <div class="home__preview">
          <img :src="makerURL" alt="Custom Penguin">
        </div>
      </div>
      <div class="home__feature">
        <card-deck
          :images="[
            `https://placepengu.in/150/200?v=${seed + 1}`,
            `https://placepengu.in/150/200?v=${seed + 2}`,
            `https://placepengu.in/150/200?v=${seed + 3}`
          ]"
          :width="150"
          :height="200"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardDeck from '@/components/CardDeck.vue'

import { validationMixin } from 'vuelidate'
import { required, between, numeric } from 'vuelidate/lib/validators'

export default {
  name: 'Home',
  mixins: [validationMixin],
  components: {
    CardDeck
  },
  data: () => ({
    seed: new Date().getTime(),
    makerWidth: 200,
    makerHeight: 200,
    makerFit: 'cover',
    makerFormat: 'jpg',

    filteredWidth: 200,
    filteredHeight: 200
  }),
  validations: {
    makerWidth: {
      required,
      numeric,
      between: between(1, 200)
    },
    makerHeight: {
      required,
      numeric,
      between: between(1, 200)
    }
  },
  methods: {
    updateSize () {
      if (!this.$v.makerWidth.$invalid) this.filteredWidth = this.$v.makerWidth.$model
      if (!this.$v.makerHeight.$invalid) this.filteredHeight = this.$v.makerHeight.$model
    }
  },
  computed: {
    makerURL () {
      return `https://placepengu.in/${this.filteredWidth}/${this.filteredHeight}/${this.makerFit}/${this.makerFormat}`
    }
  },
  mounted () {
    this.updateSize()
  },
  watch: {
    makerWidth () {
      this.updateSize()
    },
    makerHeight () {
      this.updateSize()
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px){
    display: block;
    margin: 0 auto;
  }

  &__intro {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;

    overflow: hidden;

    @media screen and (max-width: 900px){
      flex-direction: column-reverse;
      margin: 0 auto;
    }
  }

  &__maker {
    input, select, input::placeholder {
      background-color: #161616;
      color: #fff;
    }

    input, select {
      outline: none;
      width: 20%;
      height: 2rem;
      border: none;
      border-radius: .25rem;
      padding: 0 .25rem;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &.invalid {
        box-shadow: 0px 0px 0px .1rem #ff0000;
        animation: shake .5s linear;
      }
    }
  }

  &__limits {
    color: #4b4747;
  }

  &__preview {
    background-color: #161616;
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
