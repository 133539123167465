<template>
  <div class="deck">
    <img
      class="card"
      v-for="(image, index) in images"
      :key="index"
      :src="image"
      :alt="`Card ${index + 1}`"
      :width="width"
      :height="height"
    >
  </div>
</template>

<script>
export default {
  name: 'card-deck',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  }
}
</script>

<style lang="scss" scoped>
.deck {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    position: relative;
    display: inline-block;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='135' height='140' viewBox='0 0 135 140' xmlns='http://www.w3.org/2000/svg' fill='%23000'%3E%3Crect y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='30' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='60' width='15' height='140' rx='6'%3E%3Canimate attributeName='height' begin='0s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='90' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='120' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20%;
    padding: .25rem;
    border-radius: .25rem;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    &:first-of-type {
      z-index: 1;
      animation: cardLeft 1.25s ease forwards;
    }

    &:not(:first-of-type):not(:last-of-type) {
      z-index: 3;
      animation: cardMiddle 1.25s ease forwards;
    }

    &:last-of-type {
      z-index: 2;
      animation: cardRight 1.25s ease forwards;
    }
  }
}

@keyframes cardLeft {
  from {
    transform: scale(.9) translateX(100%) translateY(5%) rotateZ(0deg);
    opacity: 0;
  }
  to {
    transform: scale(.9) translateX(50%) translateY(5%) rotateZ(-15deg);
    opacity: 1;
  }
}

@keyframes cardMiddle {
  from {
    transform: translateY(5%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes cardRight {
  from {
    transform: scale(.9) translateX(-100%) translateY(5%) rotateZ(0deg);
    opacity: 0;
  }
  to {
    transform: scale(.9) translateX(-50%) translateY(5%) rotateZ(15deg);
    opacity: 1;
  }
}
</style>
